export const customMapStyles = [
    {
      elementType: "geometry",
      stylers: [{ color: "#eef8c5" }], 
    },
    {
      elementType: "labels.icon",
      stylers: [{ visibility: "on" }], 
    },
    {
      elementType: "labels.icon",
      stylers: [{ color: "#9aa572" }], 
    },
    {
      elementType: "labels.text.fill",
      stylers: [{ color: "#9aa572" }], 
    },
    {
      elementType: "labels.text.stroke", 
      stylers: [
        { color: "#eef8c5" }, 
        { weight: 0 }, 
      ],
    },
    {
      featureType: "road",
      elementType: "geometry",
      stylers: [{ color: "#d5dfac" }], 
    },
    {
      featureType: "road",
      elementType: "geometry.stroke",
      stylers: [{ color: "#d5dfac" }], 
    },
  ];
  