import image from "../../assets/Images/EarthworksQuantites/image.jpg";
import imageone from "../../assets/Images/EarthworksQuantites/image-1.jpg";

const contentBackground = {
    lineOne:"EARTHWORK & MATERIAL QUANTITIES",
    lineTwo:"COMPREHENSIVE",
    lineThree:"EARTHWORK QUANTITIES",
    lineFour:"NATIONWIDE",
    para:" Using detailed civil construction plans and CAD files, Project Prime delivers precise earthwork and material quantities tailored to meet specific project needs. From basic earthwork estimates to detailed quantity reports, experienced technicians ensure accuracy, helping projects stay on schedule and within budget.",
    button:"CHECK PRICING"
  };

const earthWorksData = [
  "BULK EARTHWORKS ANALYSIS FOR ROAD AND SITE PREPARATION",
  "CLEAR, GRUB, AND TOPSOIL STRIPPING TO PREP SITES FOR CONSTRUCTION",
  "SITE IMPROVEMENT VOLUMES TO MEASURE ENHANCED AREAS",
  "COMPARATIVE AREA QUANTITIES FOR PRECISE MATERIAL PLANNING",
  "PROPOSED HAUL ROADS FOR EFFICIENT MATERIAL TRANSPORT PLANNING",
];

const earthContent = {
  title: "BULK EARTHWORKS MODELS",
  content:
    "Specializing in bulk earthworks models designed to make large-scale excavation and grading projects run smoothly. With years of hands-on experience, we create models that help optimize material movement and keep site preparation efficient. Whether it's leveling a site or tackling complex cut- and-fill work, our models bring the precision needed to get the job done right",
  image: imageone,
};

const imageTop = {
  image: image,
};

const header = [
  "HERE ARE SOME OF THE EARTHWORKS ",
  "QUANTITIES COMMONLY PROVIDED:",
];

const earthWorkContent = {
  earthWorksData,
  earthContent,
  imageTop,
  header,
  contentBackground
};

export default earthWorkContent;
